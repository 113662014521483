import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Container, Row, Col, Button } from 'reactstrap'
import Legal from '../shared/Legal'
export default class Hero extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            page: contentfulIntegrationsPage {
              heroTitle
              heroText {
                g: childMarkdownRemark {
                  html
                }
              }
              heroImage {
                title
                description
                file {
                  url
                }
              }
            }
          }
        `}
        render={({ page }) => (
          <div>
            <Container className="py-5">
              <Row className="mb-5">
                <Col
                  md="4"
                  className=" d-flex flex-column justify-content-between"
                >
                  <h3 className="mb-4 text-gold ">{page.heroTitle}</h3>

                  {/* <div className="placeholder" /> */}
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: page.heroText.g.html,
                      }}
                    />
                  </div>
                  <Row>
                    <Col md="8">
                      <Button
                        style={{ height: '60px', lineHeight: 1 }}
                        size="lg"
                        tag={Link}
                        state={{ selected: 'Request a Callback' }}
                        to="/contact"
                        className="px-7 text-white py-3  text-center   primary-btn-gradient"
                      >
                        {' '}
                        Request a Callback
                      </Button>
                      <Legal />
                    </Col>
                  </Row>
                </Col>

                <Col md={{ size: 6, offset: 2 }}>
                  <img
                    alt={page.heroImage.description}
                    src={page.heroImage.file.url}
                    title={page.heroImage.title}
                    className="img-fluid w-100 mb-3 border-40"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      />
    )
  }
}
